import * as ScrollMagic from "@modules/scrollmagic";
import {TweenMax} from "@modules/gsap";
import { isEditMode } from '@js/utils';

export default class EffectExperienceTeaser {
    constructor(rootNode, props) {
        this.$refs = {
            rootNode,
            articles: rootNode.querySelectorAll('.article'),
            windowWidth: window.innerWidth
        };

        if (isEditMode()) return;

        if(this.$refs.windowWidth < 768) return;

        this.$refs.articles.forEach(article => {
            new ScrollMagic.Scene(
                {
                    triggerElement: this.$refs.rootNode,
                    triggerHook: 0.5,
                    duration: () => {
                        return this.$refs.rootNode.offsetHeight
                    },
                })
                .setTween(new TweenMax.to(article,
                    1,
                    {
                        top: '0',
                    })
                )
                .addTo(new ScrollMagic.Controller())
        })
    }
}