import * as ScrollMagic from "@modules/scrollmagic";
import {TweenMax} from "@modules/gsap";
import { isEditMode } from '@js/utils';

export default class ImageText {
    constructor(rootNode, props) {
        this.$refs = {
            rootNode,
            content: rootNode.querySelector('.content'),
            background: rootNode.querySelector('.background'),
            windowWidth: window.innerWidth
        };

        if (isEditMode()) return;

        new ScrollMagic.Scene({
                triggerElement: this.$refs.rootNode,
                triggerHook: 0.5,
                duration: () => {
                    return this.$refs.rootNode.offsetHeight
                },
            })
            .setTween(new TweenMax.to(
                    this.$refs.background,
                    1,
                    {
                        top: '-50px',
                        bottom: '-50px',
                        left: '-250px',
                        right: '-250px',
                    }
                )
            )
            .addTo(new ScrollMagic.Controller())

        if(this.$refs.windowWidth < 1280) return;

        new ScrollMagic.Scene({
                triggerElement: this.$refs.rootNode,
                triggerHook: 0.5,
                duration: () => {
                    return this.$refs.rootNode.offsetHeight
                },
            })
            .setTween(new TweenMax.fromTo(
                    this.$refs.content,
                    1,
                    {
                        transform: 'translateY(-100px)'
                    },
                    {
                        transform: 'translateY(100px)'
                    }
                )
            )
            .addTo(new ScrollMagic.Controller())
    }
}