// Components
export { default as AbTextAnimation } from '@WebsiteBundle/views/components/TextAnimation/TextAnimation';
export { default as ImageText } from '@WebsiteBundle/views/components/ImageText/ImageText';
export { default as ProductTeaser } from '@WebsiteBundle/views/components/ProductTeaser/ProductTeaser';
export { default as EffectExperienceTeaser } from '@WebsiteBundle/views/components/EffectExperienceTeaser/EffectExperienceTeaser';
export { default as CookieConsent } from '@WebsiteBundle/views/components/CookieConsent/CookieConsent';

// Detail pages
export { default as EffectDetail } from '@WebsiteBundle/views/effect/index.js';

// Scrollmagic
import * as ScrollMagic from "@modules/scrollmagic";
import {TweenMax} from "@modules/gsap";
import {ScrollMagicPluginGsap} from "@modules/scrollmagic-plugins";

ScrollMagicPluginGsap(ScrollMagic, TweenMax);