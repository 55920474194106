import * as ScrollMagic from "@modules/scrollmagic";
import {TweenMax} from "@modules/gsap";

export default class EffectDetail {
    constructor(rootNode, props) {
        this.$refs = {
            rootNode,
            background: rootNode.querySelector('.detail-header-bg'),
            image: rootNode.querySelector('.detail-header-img'),
            content: rootNode.querySelector('.detail-content '),
        };

        new ScrollMagic.Scene({
            triggerElement: this.$refs.rootNode,
            triggerHook: 0,
            duration: () => {
                return this.$refs.rootNode.offsetHeight / 2
            },
        })
            .setTween(new TweenMax.to(
                    this.$refs.background,
                    1,
                    {
                        top: '-50px',
                        bottom: '-50px',
                        left: '-250px',
                        right: '-250px',
                    }
                )
            )
            .addTo(new ScrollMagic.Controller())

        new ScrollMagic.Scene({
            triggerElement: this.$refs.rootNode,
            triggerHook: 0,
            duration: () => {
                return this.$refs.rootNode.offsetHeight / 2
            },
        })
            .setTween(new TweenMax.to(
                    this.$refs.image,
                    1,
                    {
                        top: this.$refs.content.offsetHeight,
                    }
                )
            )
            .addTo(new ScrollMagic.Controller())
    }
}