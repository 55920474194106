window.auth = {
    async fetchUser() {
        // Get locale
        const locale = document.documentElement.lang;

        // Fetch user
        const response = await fetch(`/api/amalgerol/${locale}/user/data`);
    
        if (response.status !== 200) {
            console.log("Error while fetching user data. Status code: " + response.status);
            window.dispatchEvent(new Event("no-login"));
            return false;
        }
    
        const json = await response.json();
    
        if (!json.success) {
            console.log("Error while fetching user data. json.success = false");
            window.dispatchEvent(new Event("no-login"));
            return false;
        }
    
        const user = json.data;
    
        // Safe user as global variable
        window.user = user;
    
        // Dispatch login event on window
        window.dispatchEvent(new CustomEvent("login", { detail: {user} }));

        // Return success
        return true;
    },

    async logIn(email, password) {
        // Get locale
        const locale = document.documentElement.lang;

        // Define request body
        const formData = new FormData();
        formData.append('_username', email);
        formData.append('_password', password);

        // Send login request
        await fetch(`/${locale}/account/login_check`, {
            method: 'POST',
            body: formData,
            credentials: 'include'
        });
    
        // Try to fetch user, returns true if the login was successful, false otherwise
        return await window.auth.fetchUser();
    },

    async logOut() {
        // Get locale
        const locale = document.documentElement.lang;

        // Send logout request
        await fetch(`/${locale}/account/logout`);
    
        // Unset global user variable
        window.user = null;
    
        // Dispatch logout event on window
        window.dispatchEvent(new CustomEvent("logout"));
    },

    async resetPassword(email) {
        // Get locale
        const locale = document.documentElement.lang;

        // Define request body
        const formData = new FormData();
        formData.append('username', email);
    
        // Send pw reset request
        const response = await fetch(`/${locale}/account/resetting/send-email`, {
            method: 'POST',
            body: formData,
            credentials: 'include'
        });
    
        // returns true if the request was successful, false otherwise
        // it is not possible to check whether the pw reset itself was successful
        return response.status === 200;
    }
}

// Try to fetch user immediately
window.auth.fetchUser();

